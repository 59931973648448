import * as React from "react";
import Layout from "../components/common/Layout/Layout"
import { graphql } from "gatsby"

const md = (data) => {
    const { frontmatter, html } = data.data.markdownRemark;
    console.log(frontmatter.title)
    return (<div>
        <div
            dangerouslySetInnerHTML={{ __html: html }}
        />
    </div>)
}


const GdprPage = ({ data }) => {
    return (
        <Layout>
            <section>
                <div className="container p-8">
                    {md({ data })}
                </div>
            </section>
        </Layout>
    )
}


export const query = graphql`
  {
    markdownRemark(fileAbsolutePath: {regex: "/gdpr.md/"}) {
      frontmatter {
        title
      }
      html
    }
  }
`

export default GdprPage
